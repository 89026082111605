export enum LimitedFeatures {
	ScrewRetainCrown = 2,
	ProcedureFlow = 3,
	SleeveConfirmation = 4,
	ProcedureFlowReadOnly = 5,
	EmergenceProfile = 6,
	RestorativeAddRx = 7,
	RestorativeMultiBite = 8,
	EggshellCrown = 9,
	MockupTooth = 10,
	ApplianceDetails = 11,
	PalatalScannigFeedback = 12
}
