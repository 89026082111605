import { RxNote } from '@modules/notes/models/rx-note';
import { UserRoleMapper } from '@shared/models/Mappers/user-role-mapper';
import { RoleTypeEnum } from '@shared/models/role-type';
import { LegacyRxNote, LegacyRxNoteRole } from './notes-parser.legacy';

export const defaultNoteDate = '1/1/1970 | 00:00:00 AM';

export class NotesParser {
	static readonly NOTE_DELIMITER = '\r\n---------------------------------\r\n';

	static isLegacyNote(text: string): boolean {
		const noteRegex = /created_id::/gm;

		return !noteRegex.exec(text);
	}

	createNote(params: {
		content: string;
		createdByName?: string;
		dateCreated?: string;
		role?: string;
		createdById?: string;
		isPreDefinedNote?: string;
	}): RxNote {
		const {
			content = null,
			createdById = null,
			createdByName = null,
			dateCreated = null,
			role = null,
			isPreDefinedNote = null
		} = params;

		return {
			content,
			createdById: createdById && +createdById,
			createdByName,
			dateCreated: dateCreated && this.getDateObjFromString(dateCreated).toISOString(),
			role: role && this.getRole(role),
			// isPreDefinedNote should be undefined if there is no explicit state for it in the note text
			// E.G. we know that the note is pre-defined from iTero Modeling when we add it.
			//  But we don't know if it was pre-defined when we re-open the Rx.
			isPreDefinedNote: isPreDefinedNote === 'true' ? true : isPreDefinedNote === 'false' ? false : undefined
		};
	}

	createLegacyNote(params: {
		canEdit?: boolean;
		content: string;
		dateCreated?: Date | string;
		createdByName?: string;
		createdById?: number;
		role?: LegacyRxNoteRole;
		isPreDefinedNote?: boolean;
	}): LegacyRxNote {
		const { dateCreated, content, role = null, createdByName = null, canEdit = false, createdById, isPreDefinedNote = false } = params;

		return new LegacyRxNote(dateCreated, content, role, createdByName, createdById, isPreDefinedNote, canEdit);
	}

	/**
	 * Parses raw text into notes.
	 *
	 * @param text Raw text
	 * @returns Array of notes
	 */
	parse(text: string): RxNote[] {
		if (!text) {
			return [];
		}

		const rawNotes = text.split(NotesParser.NOTE_DELIMITER);

		return rawNotes.map(rawNote => {
			const isLegacy = NotesParser.isLegacyNote(rawNote);
			const noteTokens = isLegacy ? this.parseLegacyNoteTokens(rawNote) : this.parseNoteTokens(rawNote);

			return noteTokens
				? this.createNote({
						content: noteTokens.content,
						createdById: noteTokens.createdById,
						createdByName: noteTokens.createdByName,
						dateCreated: noteTokens.dateCreated,
						role: noteTokens.role,
						isPreDefinedNote: noteTokens.isPreDefinedNote
					})
				: this.createNote({ content: rawNote });
		});
	}

	/**
	 * Maps notes to legacy notes.
	 *
	 * @param notes An array of notes
	 * @param canEdit If `true`, notes can be edited
	 * @returns An array of legacy notes
	 */
	notesToLegacy(notes: RxNote[], canEdit = false): LegacyRxNote[] {
		return notes.map(note => {
			if (note.isPreDefinedNote == null) {
				note.isPreDefinedNote = false;
			}

			return this.createLegacyNote({
				canEdit,
				content: note.content,
				dateCreated: note.dateCreated && this.getDateObjFromString(note.dateCreated),
				createdByName: note.createdByName,
				createdById: note.createdById,
				role: NotesParser.convertRoleToLegacyRole(note.role),
				isPreDefinedNote: note.isPreDefinedNote
			});
		});
	}

	serializeNotes(notes: RxNote[]): string {
		if (!notes.length) {
			return '';
		}

		const legacyNotes = this.notesToLegacy(notes);

		return legacyNotes.map((note: LegacyRxNote) => note.toString()).join(NotesParser.NOTE_DELIMITER);
	}

	deserializeNotes(text: string): NoteTokens[] {
		if (!text) {
			return [];
		}

		const rawNotes = text.split(NotesParser.NOTE_DELIMITER);
		const noteTokens = rawNotes.map(rawNote => {
			const isLegacy = NotesParser.isLegacyNote(rawNote);

			return isLegacy ? this.parseLegacyNoteTokens(rawNote) : this.parseNoteTokens(rawNote);
		});

		return noteTokens.filter(note => !!note);
	}

	getRoleAsChar(role: RoleTypeEnum): string {
		return UserRoleMapper[role];
	}

	private static convertRoleToLegacyRole(role: RoleTypeEnum): LegacyRxNoteRole {
		switch (role) {
			case RoleTypeEnum.Lab:
				return LegacyRxNoteRole.getRole(true, false);
			case RoleTypeEnum.Technician:
				return LegacyRxNoteRole.getRole(false, true);
			case RoleTypeEnum.Doctor:
				return LegacyRxNoteRole.getRole(false, false);
		}

		return null;
	}

	private static getLegacyRoleFromChar(roleChar: string): LegacyRxNoteRole {
		switch (roleChar) {
			case 'L':
				return LegacyRxNoteRole.getRole(true, false);
			case 'T':
				return LegacyRxNoteRole.getRole(false, true);
			case 'D':
				return LegacyRxNoteRole.getRole(false, false);
		}

		return null;
	}

	private parseNoteTokens(rawNote: string): NoteTokens {
		const noteRegex =
			/(\w\W+)?role:: (\w+)% created_id:: ([\w\W]+)% created_name:: ([\w\W]+)% created_date:: ([\s\S]+)% content:: ([\w\W]+)?/gm;
		const matches = noteRegex.exec(rawNote);

		if (!matches) {
			return null;
		}

		const tokens = {
			role: matches[2],
			createdById: matches[3],
			createdByName: matches[4],
			dateCreated: matches[5],
			content: matches[6]
		};

		return tokens;
	}

	private parseLegacyNoteTokens(rawNote: string): NoteTokens {
		const [metadata, ...contentArray] = rawNote.split('\n');
		const content = contentArray.join('\n');

		const matches = /\(([DTL])?\)(.+)?\(([\s\S]+)?\)/.exec(metadata);

		if (!matches) {
			return null;
		}

		return {
			role: matches[1],
			createdByName: matches[2]?.trim(),
			dateCreated: matches[3],
			content
		};
	}

	private getRole(role: string): RoleTypeEnum {
		const currentRole = Object.keys(UserRoleMapper).find(key => UserRoleMapper[key] === role);

		return +currentRole as RoleTypeEnum;
	}

	/**
	 * getting a MM/DD/YYYY | hh:mm:ss AM/PM string and converting to Date object
	 */
	private getDateObjFromString(dateStr: string): Date {
		try {
			if (Date.parse(dateStr)) {
				return new Date(dateStr);
			}

			if (!/[AaPp][Mm]/.test(dateStr)) {
				throw new Error('Unknown time format');
			}

			const dateSection = dateStr.split('|')[0].trim();
			const year = parseInt(dateSection.split('/')[2], 10);
			const month = parseInt(dateSection.split('/')[0], 10) - 1;
			const date = parseInt(dateSection.split('/')[1], 10);

			const amPm = dateStr.trim().slice(-2);
			const timeSection = dateStr.split('|')[1].replace(/\\s[AaPp][Mm]/, '');

			let hours = parseInt(timeSection.split(':')[0], 10);

			if (/[Pp][Mm]/.test(amPm) && hours < 12) {
				hours = hours + 12;
			} else if (/[Aa][Mm]/.test(amPm) && hours === 12) {
				hours = hours - 12;
			}

			const minutes = parseInt(timeSection.split(':')[1], 10);
			const seconds = parseInt(timeSection.split(':')[2], 10);

			return new Date(year, month, date, hours, minutes, seconds);
		} catch (error) {
			return this.getDateObjFromString(defaultNoteDate);
		}
	}
}

export interface NoteTokens {
	role?: string;
	createdById?: string;
	createdByName?: string;
	dateCreated?: string;
	content?: string;
	isPreDefinedNote?: string;
}
