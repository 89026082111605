export const mask = (entry: string): string => {
	if (!entry) {
		return entry;
	}

	const maxEntrySize = 3;

	if (entry.length > maxEntrySize) {
		const entrySlice = entry.slice(0, maxEntrySize);

		return `${entrySlice} ***`;
	}

	return '*'.repeat(entry.length);
};
