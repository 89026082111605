export enum SoftwareOptions {
	ChairSideMillingE4D = 2060,
	ChairSideMillingOther = 2075,
	ChairSideMillingGlidewell = 2073,
	ShouldEnableEvx = 2087,
	ReferralWorkflowScanningCenter = 2237,
	ReferralWorkflowPractice = 2238,
	ProcedureBasedRx = 2252,
	ProcedureAppliances = 2254,
	FinalRecords = 2085,
	ProcedureDenturesRemovable = 2255,
	ProcedureFixedRestorativePostAndCore = 2256,
	ProcedureImplantPlanning = 2258,
	ProcedureInvisalign = 2259,
	ProcedureInvisalignOrthoRestorative = 2260,
	ProcedureFixedRestorative = 2261,
	ProcedureStudyModelIRecord = 2265,
	OrthoModelICast = 2266,
	RxAwarenessMessageLMR = 2280,
	RxAwarenessParticipateLMR = 2281,
	AddImages = 2282,
	NGNIRI = 2430,
	EmergenceProfileScan = 2445,
	AllowToSendLater = 2452,
	EnableBannerMiniAppsInRX = 2457,
	EnableInformAccessBannerInRX = 2458,
	ExocadMiniApp = 2449,
	RestorativeMultiBite = 2455,
	EnableMockUpIndication = 2479,
	EnableApplianceDetails = 2492,
	PalateQC = 9998, // TBD
	DoAwesome = 9999
}

export enum GenderType {
	Male = 0,
	Female = 1,
	Empty = ''
}

export enum ApplicationModes {
	web = 'Web',
	oldWeb = 'WebRx',
	scanner = 'EmbeddedInScanner'
}

export enum PrintOrientation {
	portrait = 'Portrait',
	landscape = 'Landscape'
}

// defines a wand generation that provides different functionality.
export enum ProductTypes {
	evx = 'EVX',
	ng = 'NG',
	rth = 'RTH',
	ngPro = 'NGPRO',
	empty = ''
}

export enum RxVersion {
	CaseTypeFlow = 0,
	ProcedureFlow = 1
}

export enum FeatureToggleSettings {
	ScrewRetainedCrown = 'screw-retained-crown',
	ReferralWorkflow = 'Referral Workflow',
	ProcedureBasedRx = 'Procedure Based Rx',
	RxAwarenessMessageGa = 'RX Awareness Message - GA',
	AddImages = 'AddImages',
	AddRxNg = 'RestorativeAddRxForNg',
	AllowToSendLater = 'AllowToSendLater',
	RestorativeMultiBite = 'RestorativeMultiBite',
	PendoToggle = 'PendoToggle',
	PendoInitOnAppStart = 'PendoInitOnAppStart'
}

export enum TimberMessageTypes {
	info = 'info',
	error = 'error',
	warn = 'warn',
	debug = 'debug',
	trace = 'trace',
	fatal = 'fatal'
}

export enum Platforms {
	MIDC = 'MIDC',
	Scanner = 'Scanner',
	Orthocad = 'Orthocad',
	IteroLab = 'IteroLab',
	IteroModeling = 'IteroModeling',
	MAT = 'MAT'
}
