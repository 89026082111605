import { Injectable } from '@angular/core';
import { RoleTypeEnum } from '@shared/models/role-type';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { mask } from '@shared/utils/mask-util';
import { combineLatest, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { RxNote } from '../models/rx-note';
import { RxUILabNote } from '../models/rx-ui-lab-note';
import { NotesQuery } from '../state/notes-query';
import { NotesStore } from '../state/notes-store';
import { NotesParser, NoteTokens } from './notes-parser';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable()
export class NotesService {
	private readonly componentName = 'NotesService';
	private notesParser = new NotesParser();

	constructor(
		private notesStore: NotesStore,
		private notesQuery: NotesQuery,
		private shellQuery: ShellQuery,
		private loggerService: LoggerService
	) {}

	getRoleAsChar(role: number): string {
		return this.notesParser.getRoleAsChar(role);
	}

	getNotesArray(noteString: string): RxNote[] {
		return this.notesParser.parse(noteString);
	}

	setNotesArray(): Observable<[string, RxNote[]]> {
		return combineLatest([this.shellQuery.notes$, this.shellQuery.notesArray$]).pipe(
			filter(([notes, notesArray]) => !!notes || !!notesArray),
			tap(([notes, notesArray]: [string, RxNote[]]) => {
				this.logNotesBeforeParse(notes, notesArray);
				this.updateNotes(notesArray ?? this.notesParser.parse(notes));
			})
		);
	}

	updateNotes(notes: RxNote[]) {
		const serializedNotes = this.notesParser.serializeNotes(notes);

		this.logNotesAfterParse(serializedNotes);
		this.notesStore.update({
			notesArray: [...notes],
			notes: serializedNotes,
			labNotes: this.notesParser.notesToLegacy(notes)
		});
	}

	toNotesString(notes: RxNote[]) {
		return this.notesParser.serializeNotes(notes);
	}

	addLabNote(newNote): RxUILabNote {
		const labNote = this.notesParser.createLegacyNote({
			content: newNote.content,
			createdByName: newNote.createdByName,
			dateCreated: new Date().toISOString(),
			canEdit: true
		});

		const newNoteWithFullDetails = this.notesParser.createNote({
			...newNote,
			createdById: newNote.contactId,
			dateCreated: labNote.dateCreated,
			role: RoleTypeEnum[RoleTypeEnum.Lab][0]
		});
		const newNotesArray = [...(this.notesQuery.notesArray ?? []), newNoteWithFullDetails];

		this.updateNotes(newNotesArray);

		return labNote;
	}

	notesFilter(notes: RxNote[]): RxNote[] {
		return notes.filter(note => note.content.trim());
	}

	notesSortByDateDescending(notes: RxNote[]): RxNote[] {
		const notesWithoutDate = notes.filter(note => !note.dateCreated || note.dateCreated === '');

		const notesWithoutAutoNote = notes.filter(nt => nt.createdByName && nt.dateCreated);

		const notesWithDate = this.sortArray(notesWithoutAutoNote);

		this.scanningCenterAutomaticNoteOrder(notes, notesWithDate, notesWithoutDate);

		return notesWithDate.concat(notesWithoutDate);
	}

	private sortArray(array: RxNote[]) {
		return array.sort((note1, note2) => Date.parse(note2.dateCreated) - Date.parse(note1.dateCreated));
	}

	private scanningCenterAutomaticNoteOrder(notes: RxNote[], notesWithDate: RxNote[], notesWithoutDate: RxNote[]): void {
		const oneSecond = 1000;
		const scanningCenterAutomaticNote = notes.find(note =>
			this.isPatternContainsWords(note.content, ['Referring Doctor', 'Company', 'Address', 'Phone'])
		);

		if (!scanningCenterAutomaticNote) {
			return;
		}

		const indexForAutoNote = notesWithDate.findIndex(n => n.createdByName.trim() !== notesWithDate[0].createdByName.trim());

		const lastNoteFromDoctorDate = new Date(
			notesWithDate[indexForAutoNote === -1 ? notesWithDate.length - 1 : indexForAutoNote].dateCreated
		);

		scanningCenterAutomaticNote.dateCreated = new Date(lastNoteFromDoctorDate.getTime() + oneSecond).toISOString();

		const automaticNoteWioutDateIndex = notesWithoutDate?.indexOf(scanningCenterAutomaticNote);

		if (automaticNoteWioutDateIndex > -1 && notesWithoutDate?.length > 0) {
			notesWithoutDate.splice(automaticNoteWioutDateIndex, 1);
		}

		if (indexForAutoNote === -1) {
			notesWithDate.push(scanningCenterAutomaticNote);
		} else {
			notesWithDate.splice(indexForAutoNote, 0, scanningCenterAutomaticNote);
		}
	}

	private isPatternContainsWords(target: string, pattern: string[]): boolean {
		return pattern.every(word => target.includes(word));
	}

	private logNotesBeforeParse(notes: string, notesArray: RxNote[]) {
		const tokens = this.notesParser.deserializeNotes(notes);
		const maskedTokens = tokens.map(note => this.mask(note));
		const maskedNotesArray = notesArray?.map(note => this.mask(note));

		const maskedTokensJson = JSON.stringify(maskedTokens.length ? maskedTokens : mask(notes));
		const notesArrayJson = JSON.stringify(maskedNotesArray ?? []);

		this.loggerService.info(`Notes before parse: notes ${maskedTokensJson} notesArray ${notesArrayJson}`, {
			module: this.componentName
		});
	}

	private logNotesAfterParse(serializedNotes: string) {
		const tokens = this.notesParser.deserializeNotes(serializedNotes);
		const maskedTokens = tokens.map(note => this.mask(note));

		const maskedTokensJson = JSON.stringify(maskedTokens.length ? maskedTokens : mask(serializedNotes));

		this.loggerService.info(`Notes after parse ${maskedTokensJson}`, { module: this.componentName });
	}

	private mask(note: NoteTokens | RxNote) {
		const { role, createdByName, content, dateCreated } = note;

		return { role, createdByName: mask(createdByName), dateCreated, content: mask(content) };
	}
}
