import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { initFn } from './bootstrap/init';

const { providers } = initFn();

platformBrowserDynamic(providers)
	.bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch(err => console.error(err));
