import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

export interface fontMock {
	name: string;
    file: string;
	classList: string[];
	innerHTML: string;
}

@Injectable({
	providedIn: 'root'
})
export class FontsPreloadService implements OnDestroy {
	private fontLoadAwaiter;
	private entriesAll = 0;

	constructor(private http: HttpClient) {}

	loadPreloadSettings(): Promise<void> {
		const baseUrl = (document.getElementsByTagName('rx-app')[0] as any)?.staticFilesEndpoint || '';

		return this.http
			.get(baseUrl + '/assets/config/preload.settings.json')
			.toPromise()
			.then((data) => {
				this.fontsPreload(data['fontsPreload'])
			});
	}

	private fontsPreload(preloadfonts: fontMock[]) {
		this.generateFontsMockItem(preloadfonts).then(fonts => this.awaitFontLoad(fonts as HTMLElement[]));
	}

	private awaitFontLoad(fonts: HTMLElement[], entriesAll: number = 0) {
		this.fontLoadAwaiter = setTimeout(() => {
			const entries = performance.getEntries();
			this.entriesAll = entries.length;
			const resources = entries.filter(entry => entry['initiatorType'] === 'css') as PerformanceEntry[];

			const findLoadedFont = fonts.find(font => resources.find(resource => resource.name.includes(font.id)));

			if (!findLoadedFont) {
				this.awaitFontLoad(fonts, this.entriesAll);
			} else {
				const index = fonts.indexOf(findLoadedFont);
				fonts.splice(index, 1);
				findLoadedFont.remove();
			}

			if (fonts.length === 0 || entriesAll === this.entriesAll) {
				for (const font of fonts) {
					font.remove();
				}
				clearTimeout(this.fontLoadAwaiter);
			} else {
				this.awaitFontLoad(fonts, this.entriesAll);
			}
		}, 1000);
	}

	private generateFontsMockItem(preloadfonts: fontMock[]) {
		return new Promise(resolve => {
			const mockFonts = [] as HTMLElement[];
			for (const font of preloadfonts) {
				const mockFontIcon = document.createElement('i');
				mockFontIcon.setAttribute('class', font.classList.join(' '));
				mockFontIcon.id = font.file;
				mockFontIcon.innerHTML = font.innerHTML;
				mockFontIcon.style.visibility = 'hidden';
				mockFontIcon.style.position = 'absolute';
				document.body.appendChild(mockFontIcon);
				mockFonts.push(mockFontIcon);
			}
			resolve(mockFonts);
		});
	}

	ngOnDestroy() {
		clearTimeout(this.fontLoadAwaiter);
	}
}
